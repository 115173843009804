import querystring from 'qs';
import {ApiException} from "../exception/ApiException";
import {Orders} from "../order/Order";

export type UrlOrders = Array<string>;

export type Filters = {[key: string]: string};

export interface UrlPagination {
    page?: number;
    itemsPerPage?: number;
    countTotalResults?: boolean;
}

export interface Search {
    relationIncludes?: Array<string>;
    filters?: Filters;
    orders?: UrlOrders;
    pagination?: UrlPagination;
}

export interface SuccessfulSearchResult<T> {
    items: Array<T>
    resultNumber?: number;
}

export type FailedSearch = Omit<ApiException, 'path'>;

export type SearchResult<T> = SuccessfulSearchResult<T> | FailedSearch;

export function buildUrlFromSearch(
    baseUrl: string,
    search: Search
) : string {
    const { relationIncludes, filters, orders, pagination } = search;

    //TODO: improvement -> move url orders building here

    return buildSearchUrl(baseUrl, relationIncludes, filters, orders, pagination);
}

export function buildSearchUrl(
    baseUrl: string,
    relationIncludes: Array<string> = [],
    filters: Filters = {},
    orders: UrlOrders = [],
    pagination: UrlPagination = {}
) : string {
    const joinedRelationIncludes = relationIncludes.join(',');
    const joinedOrders = orders.join(',');
    const resolvedPagination = {
        ...pagination,
        ...(pagination.countTotalResults && { countTotalResults : '1'})
    };
    const resolvedFilters = Object.entries(filters).reduce((acc, [key, value]) => {
        if(value === null || value === '') {
            return acc;
        }

        acc[key] = value;

        return acc;
    }, {});

    return `${baseUrl}?${querystring.stringify({
        filters: resolvedFilters,
        ...(orders.length && {orders: joinedOrders}),
        ...(relationIncludes.length > 0 && {includes: joinedRelationIncludes}),
        ...resolvedPagination,
    })}`;
}

export function buildUrlOrdersFromOrders(orders: Orders) : UrlOrders {
    const urlOrders: UrlOrders = [];

    for(let [field, direction] of Object.entries(orders)) {
        if(!direction) {
            continue;
        }

        urlOrders.push(direction === 'ASC' ? field : `-${field}`);
    }

    return urlOrders;
}
