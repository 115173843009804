import {buildUrlFromSearch, Search} from "./search";
import {ContactDto} from "../core/Contact";
import apiClient from "./apiClient";
import {buildRelationIncludesUrl} from "./detail";
import {FileDto} from "../core/File";

export async function fetchContacts(search: Search): Promise<Response> {
    return await apiClient(
        buildUrlFromSearch('/api/contact', search),
        {method: 'GET',}
    );
}

export async function fetchContact(id: string, relationIncludes: Array<string> = []): Promise<Response> {
    return await apiClient(
        buildRelationIncludesUrl(`/api/contact/${id}`, relationIncludes)
        , {method: 'GET'}
    );
}

export async function addContact(contact: ContactDto): Promise<Response> {
    return await apiClient('/api/contact', {
        method: 'POST',
        body: JSON.stringify(contact)
    });
}

export async function updateContact(id: string, contact: ContactDto): Promise<Response> {
    return await apiClient(`/api/contact/${id}`, {
        method: 'PUT',
        body: JSON.stringify(contact)
    });
}

export async function deleteContact(id: string): Promise<Response> {
    return await apiClient(`/api/contact/${id}`, {
        method: 'DELETE'
    });
}

export async function validateContactChangeRequest(id: string, validatedValues: ContactDto): Promise<Response> {
    return await apiClient(`/api/contact/${id}/validate_change_request`, {
        method: 'POST',
        body: JSON.stringify({
            validatedValues
        })
    });
}

export async function refuseContactChangeRequest(id: string): Promise<Response> {
    return await apiClient(`/api/contact/${id}/refuse_change_request`, {
        method: 'POST'
    });
}

export async function acceptContactRemovalRequest(id: string): Promise<Response> {
    return await apiClient(`/api/contact/${id}/accept_removal_request`, {
        method: 'POST'
    });
}

export async function refuseContactRemovalRequest(id: string): Promise<Response> {
    return await apiClient(`/api/contact/${id}/refuse_removal_request`, {
        method: 'POST'
    });
}

export async function validateContactCreationRequest(id: string): Promise<Response> {
    return await apiClient(`/api/contact/${id}/validate_creation_request`, {
        method: 'POST'
    });
}

export async function refuseContactCreationRequest(id: string): Promise<Response> {
    return await apiClient(`/api/contact/${id}/refuse_creation_request`, {
        method: 'POST'
    });
}


export async function unsubscribeToPublicNewsletter(unsubcribeToken: string): Promise<Response> {
    return await apiClient(`/api/public/contact/unsubscribe_to_public_newsletter`, {
        method: 'POST',
        body: JSON.stringify({
            id: unsubcribeToken
        })
    });
}
export async function uploadContactsXlsx(file: FileDto): Promise<Response> {
    return await apiClient(
        `/api/contact/import`,
        {method: 'POST', body: JSON.stringify({'uploadId' : file.uploadId})}
    );
}
