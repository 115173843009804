import apiClient from "./apiClient";
import {buildRelationIncludesUrl} from "./detail";
import {EventDto, ImportEventVerifiedPresencesFromTeamsDto, ManageExchangeCalendarEventDto} from "../core/Event";
import {buildUrlFromSearch, Search} from "./search";
import {DeclareEventPresenceDto, ManageEventGuestPresenceDto} from "../core/EventGuest";

export async function fetchEvents(search: Search) : Promise<Response> {
    return await apiClient(
        buildUrlFromSearch('/api/event', search),
        {method: 'GET'}
    );
}


export async function fetchEvent(id: string, relationIncludes: Array<string> = []) : Promise<Response> {
    return await apiClient(
        buildRelationIncludesUrl(`/api/event/${id}`, relationIncludes),
        {method: 'GET'}
    );
}

export async function addEvent(event: EventDto) : Promise<Response> {
    return await apiClient('/api/event', {
        method: 'POST',
        body: JSON.stringify(event)
    });
}

export async function updateEvent(id: string, event: EventDto) : Promise<Response> {
    return await apiClient(`/api/event/${id}`, {
        method: 'PUT',
        body: JSON.stringify(event)
    });
}

export async function deleteEvent(id: string) : Promise<Response> {
    return await apiClient(`/api/event/${id}`, {
        method: 'DELETE'
    });
}

export async function addContactAdditionalGuest(eventId: string, contactId: string) : Promise<Response> {
    return await apiClient(`/api/event/${eventId}/add_contact_additional_guest`, {
        method: 'POST',
        body: JSON.stringify({
            contactId
        })
    });
}

export async function addGroup(eventId: string, groupId: string) : Promise<Response> {
    return await apiClient(`/api/event/${eventId}/add_group`, {
        method: 'POST',
        body: JSON.stringify({
            groupId
        })
    });
}

export async function deleteContactAdditionalGuestFromEvent(eventId: string, contactAdditionalGuestId: string) : Promise<Response> {
    return await apiClient(`/api/event/${eventId}/remove_contact_additional_guest`, {
        method: 'POST',
        body: JSON.stringify({
            contactId: contactAdditionalGuestId
        })
    });
}

export async function deleteGroupFromEvent(eventId: string, groupId: string) : Promise<Response> {
    return await apiClient(`/api/event/${eventId}/remove_group`, {
        method: 'POST',
        body: JSON.stringify({
            groupId
        })
    });
}

export function declareEventPresence(declareEventPresenceDto: DeclareEventPresenceDto) : Promise<Response> {
    return apiClient(`/api/event/declare_presence`, {
        method: 'POST',
        body: JSON.stringify(declareEventPresenceDto)
    });
}

export async function fetchEventGuest(token: string, relationIncludes: Array<string> = []) : Promise<Response> {
    return await apiClient(
        buildRelationIncludesUrl(`/api/event/event_guest/${token}`, relationIncludes),
        {method: 'GET'}
    );
}

export async function sendEventInvitations(eventId: string) : Promise<Response> {
    return await apiClient(`/api/event/${eventId}/send_invitations`, {
        method: 'POST'
    });
}

export async function sendEventInvitation(eventGuestId: string) : Promise<Response> {
    return await apiClient(`/api/event/event_guest/${eventGuestId}/send_invitation`, {
        method: 'POST'
    });
}

export async function sendEventInformation(eventGuestId: string) : Promise<Response> {
    return await apiClient(`/api/event/event_guest/${eventGuestId}/send_information`, {
        method: 'POST'
    });
}

export async function createExchangeCalendarEvent(eventId: string, manageExchangeCalendarEventDto?: ManageExchangeCalendarEventDto) : Promise<Response> {
    return await apiClient(`/api/event/${eventId}/create_exchange_calendar_event`, {
        method: 'POST',
        body: JSON.stringify(manageExchangeCalendarEventDto)
    });
}

export async function updateExchangeCalendarEvent(eventId: string, manageExchangeCalendarEventDto?: ManageExchangeCalendarEventDto) : Promise<Response> {
    return await apiClient(`/api/event/${eventId}/update_exchange_calendar_event`, {
        method: 'POST',
        body: JSON.stringify(manageExchangeCalendarEventDto)
    });
}

export async function fetchEventGuests(search: Search) : Promise<Response> {
    return await apiClient(
        buildUrlFromSearch('/api/event_guest', search),
        {method: 'GET'}
    );
}

export async function manageEventGuestPresence(eventGuestId: string, manageEventGuestPresenceDto: ManageEventGuestPresenceDto) : Promise<Response> {
    return await apiClient(`/api/event/event_guest/${eventGuestId}/manage_presence`, {
        method: 'POST',
        body: JSON.stringify(manageEventGuestPresenceDto)
    });
}

export function importEventVerifiedPresencesFromTeams(eventId: string, importEventVerifiedPresencesFromTeamsDto: ImportEventVerifiedPresencesFromTeamsDto) : Promise<Response> {
    return apiClient(`/api/event/${eventId}/import_verified_presences_from_teams`, {
        method: 'POST',
        body: JSON.stringify(importEventVerifiedPresencesFromTeamsDto)
    });
}

export function fetchExtraEventAttendees(search: Search) : Promise<Response> {
    return apiClient(
        buildUrlFromSearch('/api/extra_event_attendee', search), {
        method: 'GET'
    });
}

export function closeEvent(eventId: string) : Promise<Response> {
    return apiClient(`/api/event/${eventId}/close`, {
        method: 'POST'
    });
}