import {buildUrlFromSearch, Search} from "./search";
import apiClient from "./apiClient";
import {buildRelationIncludesUrl} from "./detail";
import {MailCampaignDto} from "../core/MailCampaign";

export async function fetchMailCampaigns(search: Search): Promise<Response> {
    return await apiClient(
        buildUrlFromSearch('/api/mail_campaign', search),
        {method: 'GET'}
    );
}

export async function fetchMailCampaign(id: string, relationIncludes: Array<string> = []): Promise<Response> {
    return await apiClient(
        buildRelationIncludesUrl(`/api/mail_campaign/${id}`, relationIncludes)
        , {method: 'GET'}
    );
}

export async function addMailCampaign(mailCampaign: MailCampaignDto): Promise<Response> {
    return await apiClient('/api/mail_campaign', {
        method: 'POST',
        body: JSON.stringify(mailCampaign)
    });
}

export async function updateMailCampaign(id: string, mailCampaign: MailCampaignDto): Promise<Response> {
    return await apiClient(`/api/mail_campaign/${id}`, {
        method: 'PUT',
        body: JSON.stringify(mailCampaign)
    });
}

export async function deleteMailCampaign(id: string): Promise<Response> {
    return await apiClient(`/api/mail_campaign/${id}`, {
        method: 'DELETE'
    });
}

export async function launchMailCampaign(id: string): Promise<Response> {
    return await apiClient(`/api/mail_campaign/${id}/send`, {
        method: 'POST'
    });
}

export async function launchTestMailCampaign(id: string): Promise<Response> {
    return await apiClient(`/api/mail_campaign/${id}/send/test`, {
        method: 'POST'
    });
}

export async function sendMail(mailCampaignRecipientId: string): Promise<Response> {
    return await apiClient(`/api/mail_campaign_recipient/${mailCampaignRecipientId}/send_mail`, {
        method: 'POST'
    });
}