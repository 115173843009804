import {useMemo} from 'react';
import dayjs from 'dayjs';

export default function Date({ date, format }: {
  date: Date | string | null;
  format?: string;
}) {
  const formattedDate = useMemo(() => {
    if (!date) {
      return '';
    }

    let formattedDate = dayjs(date).locale('fr');

    return formattedDate.format(format ? format : 'DD/MM/YY HH:mm');
  }, [date, format]);

  return <>{formattedDate}</>;
}
