import {
    Alert,
    AlertDescription,
    AlertTitle,
    AlertIcon,
    As,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Flex,
    Icon,
    Link,
    List,
    ListItem,
    Text,
} from "@chakra-ui/react";
import {PiPlusBold} from "react-icons/pi";
import React from "react";
import NextLink from "next/link";

export default function DomainCard({ iconAs, label, isPending, isError, items = [], renderItem, listHref }: {
    children?: React.ReactNode,
    iconAs: As,
    label: string,
    isPending: boolean,
    isError: boolean,
    items: any[],
    renderItem: (item: any) => React.ReactNode,
    listHref?: string,
}) {
    return (
        <Card
            p={["", 6]}
            borderRadius={10}
            width={["", "30rem"]}
            minWidth={["", "30rem"]}
        >
            <CardHeader
                textTransform="uppercase"
                letterSpacing={-1}
                color="gray.base"
                fontWeight="600"
                lineHeight={5}
                fontSize="xl"
                py={2}
                px={[2, 0]}
            >
                <Flex align="center" wrap={["wrap", "nowrap"]} gap={3}>
                    <Icon as={iconAs} boxSize={10} />
                    <Text display="flex" flexWrap="wrap" wordBreak="break-word">
                        {label}
                    </Text>
                </Flex>
            </CardHeader>

            <CardBody px={[2, 0]} py={[2, 4]}>
                <List>
                    {isError && (
                        <Alert status="error">
                            <AlertIcon />
                            <AlertTitle mr={2}>Erreur</AlertTitle>
                            <AlertDescription>Impossible de charger les {label}.</AlertDescription>
                        </Alert>
                    )}
                    {!isPending && !isError && items.length === 0 && (
                        <Alert status="info">
                            <AlertIcon />
                            <AlertDescription>Aucun résultat.</AlertDescription>
                        </Alert>
                    )}
                    {!isPending && items.map((item, index) => (
                        <ListItem
                            key={index}
                            mb={[
                                index === items.length ? 0 : 6,
                                index === items.length ? 0 : 4,
                            ]}
                        >
                            {renderItem(item)}
                        </ListItem>
                    ))}
                </List>
            </CardBody>

            <CardFooter px={["", 0]}>
                <Link
                    color="brand.300"
                    textDecoration="underline"
                    fontWeight="700"
                >
                    <Flex align="center" gap={3}>
                        <Flex
                            align="center"
                            justify="center"
                            background="brand.300"
                            borderRadius="full"
                            height={7}
                            width={7}
                        >
                            <Icon as={PiPlusBold} color="white" boxSize={5} />
                        </Flex>
                        <Link as={NextLink} href={listHref || ''}>Voir plus</Link>
                    </Flex>
                </Link>
            </CardFooter>
        </Card>
    )
}