import {buildUrlFromSearch, Search} from "./search";
import apiClient from "./apiClient";
import {buildRelationIncludesUrl} from "./detail";
import {ArchiveCompanyDto, CompanyDto, SetCompanyMainContactDto} from "../core/Company";

export async function fetchCompanies(search: Search) : Promise<Response> {
    return await apiClient(
        buildUrlFromSearch('/api/company', search),
        {method: 'GET',}
    );
}

export async function fetchCompany(id: string, relationIncludes: Array<string> = []) : Promise<Response> {
    return await apiClient(
        buildRelationIncludesUrl(`/api/company/${id}`, relationIncludes),
        {method: 'GET'}
    );
}

export async function addCompany(company: CompanyDto) : Promise<Response> {
    return await apiClient('/api/company', {
        method: 'POST',
        body: JSON.stringify(company)
    });
}

export async function updateCompany(id: string, company: CompanyDto) : Promise<Response> {
    return await apiClient(`/api/company/${id}`, {
        method: 'PUT',
        body: JSON.stringify(company)
    });
}

export async function archiveCompany(id: string, archiveCompany: ArchiveCompanyDto) : Promise<Response> {
    return await apiClient(`/api/company/${id}/archive`, {
        method: 'POST',
        body: JSON.stringify(archiveCompany)
    });
}

export async function deleteCompany(id: string) : Promise<Response> {
    return await apiClient(`/api/company/${id}`, {
        method: 'DELETE'
    });
}

export async function setCompanyMainContact(id: string, setCompanyMainContactDto: SetCompanyMainContactDto) : Promise<Response> {
    return await apiClient(`/api/company/${id}/set_main_contact`, {
        method: 'POST',
        body: JSON.stringify(setCompanyMainContactDto)
    });
}