import {
    Box,
    Flex,
    HStack,
    Icon,
    Link,
    Text,
    VStack
} from "@chakra-ui/react";
import {
    PiAddressBook,
    PiArrowLineRightBold,
    PiBuildings,
    PiEnvelope
} from "react-icons/pi";
import React, {ReactElement} from "react";
import Layout from "../components/container/layout/Layout";
import type { NextPageWithLayout } from './_app'
import {fetchCompanies} from "../lib/api/companyApi";
import {useQuery, UndefinedInitialDataOptions} from "@tanstack/react-query";
import {FailedSearch, SearchResult, SuccessfulSearchResult} from "../lib/api/search";
import {fetchContacts} from "../lib/api/contactApi";
import DomainCard from "../components/container/home/DomainCard";
import {Company} from "../lib/core/Company";
import {Contact} from "../lib/core/Contact";
import {Event} from "../lib/core/Event";
import {fetchEvents} from "../lib/api/eventApi";
import {MailCampaign} from "../lib/core/MailCampaign";
import {fetchMailCampaigns} from "../lib/api/mailCampaignApi";
import Date from "../components/container/common/Date";
import NextLink from "next/link";
import {useCurrentUser} from "../hooks/authenticationManager";
import {ReadonlyUser} from "../lib/core/User";

function useLastCreatedCompanies(nbItems: number) {
    const {
        isPending: companiesPending,
        data: companiesData,
        isError: companiesError
    }: { isPending: boolean, data: SearchResult<Company>, isError: boolean } = useQuery({
        queryKey: ['companies', { type: `${nbItems}_last` }],
        queryFn: () => fetchCompanies({
            relationIncludes: [
                'sites',
                'contacts'
            ],
            pagination: {
                page: 1,
                itemsPerPage: nbItems
            },
            orders: [
                '-createdAt'
            ]
        }).then((response: Response) => response.json()),
    } as UndefinedInitialDataOptions<SearchResult<Company>, FailedSearch, SearchResult<Company>>);

    const companyItems = (companiesData && (companiesData as SuccessfulSearchResult<Company>).items) || [];

    return {
        companiesPending,
        companiesError,
        companyItems
    }
}

function useLastCreatedContacts(nbItems: number) {
    const {
        isPending: contactsPending,
        data: contactsData,
        isError: contactsError
    }: { isPending: boolean, data: SearchResult<Contact>, isError: boolean } = useQuery({
        queryKey: ['contacts', { type: `${nbItems}_last` }],
        queryFn: () => fetchContacts({
            relationIncludes: [
                'company',
                'displayCivility'
            ],
            pagination: {
                page: 1,
                itemsPerPage: nbItems
            },
            orders: [
                '-createdAt'
            ]
        }).then((response: Response) => response.json())
    } as UndefinedInitialDataOptions<SearchResult<Contact>, FailedSearch, SearchResult<Contact>>);

    const contactItems = (contactsData && (contactsData as SuccessfulSearchResult<Contact>).items) || [];

    return {
        contactsPending,
        contactsError,
        contactItems
    }
}

function useEventsToGo(nbItems: number, userId: string) {
    const {
        isPending: eventsPending,
        data: eventsData,
        isError: eventsError
    }: { isPending: boolean, data: SearchResult<Event>, isError: boolean } = useQuery({
        queryKey: ['events', { type: `${nbItems}_to_go` }],
        queryFn: () => fetchEvents({
            relationIncludes: [
                'mail',
                'registeredContactsNb',
                'groupEvents.group'
            ],
            pagination: {
                page: 1,
                itemsPerPage: nbItems
            },
            orders: [
                'startAt'
            ],
            filters: {
                toGo: '1',
                userParticipatingIn: userId
            }
        }).then((response: Response) => response.json()),
        enabled: !!userId
    } as UndefinedInitialDataOptions<SearchResult<Event>, FailedSearch, SearchResult<Event>>);

    const eventItems = (eventsData && (eventsData as SuccessfulSearchResult<Event>).items) || [];

    return {
        eventsPending,
        eventsError,
        eventItems
    }
}

function useLastMailCampaignsSent(nbItems: number, userId: string) {
    const {
        isPending: mailCampaignsPending,
        data: mailCampaignsData,
        isError: mailCampaignsError
    }: { isPending: boolean, data: SearchResult<MailCampaign>, isError: boolean } = useQuery({
        queryKey: ['mailCampaigns', { type: `${nbItems}_last_sent` }],
        queryFn: () => fetchMailCampaigns({
            relationIncludes: [
                'sentEmailsNb'
            ],
            pagination: {
                page: 1,
                itemsPerPage: nbItems
            },
            orders: [
                'sentAt'
            ],
            filters: {
                sent: '1',
                author: userId
            },
        }).then((response: Response) => response.json()),
        enabled: !!userId
    } as UndefinedInitialDataOptions<SearchResult<MailCampaign>, FailedSearch, SearchResult<MailCampaign>>);

    const mailCampaignItems = (mailCampaignsData && (mailCampaignsData as SuccessfulSearchResult<MailCampaign>).items) || [];

    return {
        mailCampaignsPending,
        mailCampaignsError,
        mailCampaignItems
    }
}

const Home: NextPageWithLayout = () => {
    const user: ReadonlyUser = useCurrentUser();
    const {companiesPending, companiesError, companyItems} = useLastCreatedCompanies(5);
    const {contactsPending, contactsError, contactItems} = useLastCreatedContacts(5);
    const {eventsPending, eventsError, eventItems} = useEventsToGo(5, user?.id);
    const {mailCampaignsPending, mailCampaignsError, mailCampaignItems} = useLastMailCampaignsSent(5, user?.id);

    return (
        <Flex justify="center" align="stretch" wrap="wrap" gap={[6, 10]}>
            <DomainCard
                iconAs={PiEnvelope}
                label={"Mes événements à venir"}
                isPending={eventsPending}
                isError={eventsError}
                items={eventItems}
                renderItem={(event: Event) => (
                    <Flex
                        direction={["column", "row"]}
                        justify={["", "space-between"]}
                        align={["", "flex-start"]}
                        gap={2}
                    >
                        <VStack
                            align="flex-start"
                            wrap="wrap"
                            spacing={0}
                            wordBreak="break-word"
                            flexBasis={["", "50%"]}
                            flexGrow={0}
                        >
                            <Text fontWeight="700">
                                <Link as={NextLink} href={`/evenement/${event.id}/detail`}>{event.mail.object}</Link>
                            </Text>
                            <Text>{event.groupEvents[0]?.group.title}</Text>
                            <Text>
                                <Date date={event.startAt} format={'DD/MM/YYYY HH:mm'} />
                            </Text>
                        </VStack>

                        <HStack
                            wrap="wrap"
                            justify={["flex-start", "flex-end"]}
                            wordBreak="break-word"
                            flexBasis={["", "50%"]}
                            flexGrow={0}
                        >
                            <Text>Contacts inscrits</Text>
                            <Box
                                as="span"
                                bg="brand.300"
                                color="white"
                                px={4}
                                fontWeight="600"
                                borderRadius={5}
                            >
                                {event.registeredContactsNb}
                            </Box>
                        </HStack>
                    </Flex>
                )}
                listHref={"/evenement"}
            />

            <DomainCard
                iconAs={PiEnvelope}
                label={"Mes campagnes de mail envoyées"}
                isPending={mailCampaignsPending}
                isError={mailCampaignsError}
                items={mailCampaignItems}
                renderItem={(mailCampaign: MailCampaign) => (
                    <Flex
                        direction={["column", "row"]}
                        justify={["", "space-between"]}
                        align={["", "flex-start"]}
                        gap={2}
                    >
                        <VStack
                            align="flex-start"
                            wrap="wrap"
                            spacing={0}
                            wordBreak="break-word"
                            flexBasis={["", "50%"]}
                            flexGrow={0}
                        >
                            <Text fontWeight="700">
                                <Link as={NextLink} href={`/campagne-mail/${mailCampaign.id}/detail`}>{mailCampaign.title}</Link>
                            </Text>
                            <Text><Date date={mailCampaign.sentAt}/></Text>
                        </VStack>

                        <HStack
                            wrap="wrap"
                            justify={["flex-start", "flex-end"]}
                            wordBreak="break-word"
                            flexBasis={["", "50%"]}
                            flexGrow={0}
                        >
                            <Text>Nombre d&apos;envois</Text>
                            <Box
                                as="span"
                                bg="brand.300"
                                color="white"
                                px={4}
                                fontWeight="600"
                                borderRadius={5}
                            >
                                {mailCampaign.sentEmailsNb || 0}
                            </Box>
                        </HStack>
                    </Flex>
                )}
                listHref={"/campagne-mail"}
            />

            <DomainCard
                iconAs={PiBuildings}
                label={"dernières sociétés"}
                isPending={companiesPending}
                isError={companiesError}
                items={companyItems}
                renderItem={(company: Company) => (
                    <Flex
                        direction={["column", "row"]}
                        justify={["", "space-between"]}
                        align={["", "flex-start"]}
                        gap={2}
                    >
                        <VStack
                            align="flex-start"
                            wrap="wrap"
                            spacing={0}
                            wordBreak="break-word"
                            flexBasis={["", "50%"]}
                            flexGrow={0}
                        >
                            <Text fontWeight="700">
                                <Link as={NextLink} href={`/societe/${company.id}/detail`}>{company.corporateName}</Link>
                            </Text>
                            <Link color="brand.500" fontWeight="700">
                                <Flex align="center" gap={3}>
                                    <Link as={NextLink} href={`/societe/${company.id}/detail?initialTab=1`}>{company.sitesNumber || company.sites.length} site(s)</Link>
                                    <Icon as={PiArrowLineRightBold} color="brand.500" boxSize={5} />
                                </Flex>
                            </Link>
                        </VStack>
                        <HStack
                            wrap="wrap"
                            justify={["flex-start", "flex-end"]}
                            wordBreak="break-word"
                            flexBasis={[null, "50%"]}
                            flexGrow={0}
                        >
                            <Text>Nombre de contacts</Text>
                            <Box
                                as="span"
                                bg="brand.300"
                                color="white"
                                px={4}
                                fontWeight="600"
                                borderRadius={5}
                            >
                                {company.contacts?.length || 0}
                            </Box>
                        </HStack>
                    </Flex>
                )}
                listHref={"/societe"}
            />

            <DomainCard
                iconAs={PiAddressBook}
                label={"derniers contacts"}
                isPending={contactsPending}
                isError={contactsError}
                items={contactItems}
                renderItem={(contact: Contact) => (
                    <Flex
                        direction={["column", "row"]}
                        justify={["", "space-between"]}
                        align="flex-start"
                        gap={2}
                    >
                        <VStack
                            align="flex-start"
                            wrap="wrap"
                            spacing={0}
                            wordBreak="break-word"
                            flexBasis={["", "50%"]}
                            flexGrow={0}
                        >
                            <Text fontWeight="700">{contact.displayCivility}</Text>
                            <Text>
                                <Link as={NextLink} href={`/contact/${contact.id}/detail`}>{contact.firstName} {contact.lastName}</Link>
                            </Text>
                        </VStack>

                        <VStack
                            spacing={0}
                            align="flex-end"
                            wrap="wrap"
                            wordBreak="break-word"
                            justify={["flex-start", "flex-end"]}
                            flexBasis={["", "50%"]}
                            flexGrow={0}
                        >
                            <Text>Société</Text>
                            {contact.company ? (
                                <Link as={NextLink}
                                      href={`/societe/${contact.company.id}/detail`}  color="brand.500"
                                      fontWeight="700"
                                      textAlign="right">{contact.company.corporateName}</Link>
                            ) : <Text>~</Text>}
                        </VStack>
                    </Flex>
                )}
                listHref={"/contact"}
            />
        </Flex>
    );
}

Home.getLayout = function getLayout(page: ReactElement) {
    return (
        <Layout>
            {page}
        </Layout>
    )
}

export default Home;
